<template>
	<section id="contact" class="py-15" style="background-color: #8f3f3f">
		<ContactForm style="color: white" color="transparent" :dark="true" />
	</section>
</template>

<script>
export default {
	name: 'LandingContact',
	components: {
		ContactForm: () => import('@/components/main/ContactForm.vue'),
	},
}
</script>
